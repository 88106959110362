module controllers {
    'use strict';
    export module logistics {
        interface ICommercialInvoiceUpdateScope extends ng.IScope {

            consignmentUpdateCtrl: controllers.consigment.ConsignmentExportUpdateCtrl}

        interface ICommercialInvoiceUpdateParams extends ng.ui.IStateParamsService {
            comId: number;
        }

        export class CommercialInvoiceUpdateCtrl {
            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$stateParams',
                'currencyService',
                'classificationValueService',
                'supplierService',
                'paymentMethodService',
                'termsOfPaymentService',
                'costModelService',
                "$anchorScroll",
                "$state",
                "purchaseOrderSKUService",
                "countryService",
                "incotermService",
                "termsOfDeliveryService",
                "consigneeService",
                "deliveryInstructionService",
                "bsLoadingOverlayService",
                "lookupService",
                "skuService",
                "$timeout",
                '$transitions',
                'customerService',
                'bankService',
                'shippingPriorityService',
                'routeCodeService',
                'transportModeService',
                'unLocationCodeService',
                'warehouseService',
                'cartageContractorService',
                'sundryItemService',
                'commercialInvoiceService',
                'isInbound',
                'isManifest',
                'commercialInvoiceSundryItemService',
                'menuService',
                'unitOfMeasureService',
                'commercialInvoiceSkuService',
                '$uibModal',
                'consignmentService',
                'uiGridConstants',
                'entityService',
                "goodsDeclarationLineService",
                'customsProcedureMatrixService',
                'customsProcedureMatrixMeasureService',
                "ENV"
            ];
            
            public CommercialInvoice: interfaces.logistics.ICommercialInvoice;
            
            comNumber: string;
            comId: number;

            apiSkuList: uiGrid.IGridApi;
            apiSundryList: uiGrid.IGridApi;
            apiComSalesOrdersList: uiGrid.IGridApi;
            apiComDeliveryInstructionsList: uiGrid.IGridApi;
            apiCommentsList: uiGrid.IGridApi;

            selectedTab: number = 0;
            captureInBaseCurrency: boolean = false;

            termsOfDeliveryList: Array<interfaces.applicationcore.IDropdownModel>;
            apportionmentList: Array<interfaces.applicationcore.IClassificationValue>;

            consignmentTypeDefaults: interfaces.consignment.ConsignmentTypeDefaults;
            allowCostPriceOverride: boolean;
            incotermOverride: boolean = false;
            incotermEditMode: boolean = false;
        
            useExportCostModes: boolean = false;

            commercialInvoiceRecalculateExchangeRate: interfaces.logistics.ICommercialInvoiceRecalculateExchangeRate;

            EntityAdditionalSettings : interfaces.applicationcore.EntityAdditionalSettings;
            EnableDocumentToExcel: boolean = false;

            public myHook: any;

            selectedSKUCommercialInvoiceLineId: number;

            constructor(
                private $scope: ICommercialInvoiceUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $stateParams: ICommercialInvoiceUpdateParams,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private supplierService: interfaces.master.ISupplierService,
                private paymentMethodService: interfaces.master.IPaymentMethodService,
                private termsOfPaymentService: interfaces.master.ITermsOfPaymentService,
                private costModelService: interfaces.costing.ICostModelService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService,
                private purchaseOrderSKUService: interfaces.purchase.IPurchaseOrderSKUService,
                public countryService: interfaces.applicationcore.ICountryService,
                public incotermService: interfaces.applicationcore.IIncotermService,
                public termsOfDeliveryService: interfaces.master.ITermsOfDeliveryService,
                public consigneeService: interfaces.master.IConsigneeService,
                public deliveryInstructionService: interfaces.purchase.IDeliveryInstructionService,
                public bsLoadingOverlayService,
                private lookupService: interfaces.applicationcore.ILookupService,
                public skuService: interfaces.master.ISKUService,
                public $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public customerService: interfaces.master.ICustomerService,
                public bankService: interfaces.master.IBankService,
                public shippingPriorityService: interfaces.master.IShippingPriorityService,
                public routeCodeService: interfaces.master.IRouteCodeService,
                public transportModeService: interfaces.applicationcore.ITransportModeService,
                public unLocationCodeService: interfaces.applicationcore.IUNLocationCodeService,
                public warehouseService: interfaces.master.IWarehouseService,
                public cartageContractorService: interfaces.master.ICartageContractorService,
                public sundryItemService: interfaces.master.ISundryItemService,
                public commercialInvoiceService: interfaces.logistics.ICommercialInvoiceService,
                public isInbound: boolean,
                public isManifest: boolean,
                public commercialInvoiceSundryItemService: interfaces.logistics.ICommercialInvoiceSundryItemService,
                private menuService: interfaces.applicationcore.IMenuService,
                public unitOfMeasureService: interfaces.master.IUnitOfMeasureService,
                public commercialInvoiceSkuService: interfaces.logistics.ICommercialInvoiceSkuService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                public consignmentService: interfaces.consignment.IConsignmentService,
                private uiGridConstants: uiGrid.IUiGridConstants,
                private entityService: interfaces.applicationcore.IEntityService,
                private goodsDeclarationLineService: interfaces.customs.IGoodsDeclarationLineService,
                private customsProcedureMatrixService: interfaces.tariff.ICustomsProcedureMatrixService,
                private customsProcedureMatrixMeasureService: interfaces.tariff.ICustomsProcedureMatrixMeasureService,
                public ENV: interfaces.applicationcore.serverConfig
            ) {
                this.myHook = $transitions.onSuccess({
                    to: '**.Update.CommercialInvoice',
                    from: '**.Update.CommercialInvoice.SundryItem'
                }, () => {
                    this.loadCommercialInvoice();
                    return;
                    });

                this.myHook = $transitions.onSuccess({
                    to: '**.Update.CommercialInvoice',
                    from: '**.Update.CommercialInvoice.Sku'
                }, () => {
                    this.loadCommercialInvoice();
                    return;
                    });

                    this.myHook = $transitions.onSuccess({
                        to: '**.Update.ConsignmentExport.CommercialInvoice',
                        from: '**.Update.ConsignmentExport.CommercialInvoice.Sku'
                    }, () => {
                        this.loadCommercialInvoice();
                        return;
                        });

                        this.myHook = $transitions.onSuccess({
                            to: '**.Update.ConsignmentImport.CommercialInvoice',
                            from: '**.Update.ConsignmentImport.CommercialInvoice.Sku'
                        }, () => {
                            this.loadCommercialInvoice();
                            return;
                            });                        
                
                this.myHook = $transitions.onSuccess({
                    to: '**.Update.ConsignmentExport.CommercialInvoice',
                    from: '**.Update.ConsignmentExport.CommercialInvoice.SundryItem'
                }, () => {
                    this.loadCommercialInvoice();
                    return;
                });

                

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });

                if ($stateParams.comId === null) {
                    return;
                }
                
                this.comNumber = "Com";
                
                this.loadCommercialInvoice();
            }


            CaptureInBaseCurrencyChanged() {
                if (this.apiSkuList) {
                    this.showHideSKUColumn(this.apiSkuList);
                }
            }

            showHideSKUColumn(gridApi: uiGrid.IGridApi) {
                var columnCostPrice = gridApi.grid.columns[9];
                var columnSellingPrice = gridApi.grid.columns[10];
                var columnSellingValue = gridApi.grid.columns[11];
                var columnBaseSellingPrice = gridApi.grid.columns[12];
                var columnBaseSellingValue = gridApi.grid.columns[13];

                if (this.CommercialInvoice)
                {
                  
                    if (this.CommercialInvoice.IsBaseCurrency)
                    {
                        columnCostPrice.hideColumn();
                        columnSellingPrice.hideColumn();
                        columnSellingValue.hideColumn();
                        columnBaseSellingPrice.showColumn();
                        columnBaseSellingValue.showColumn();
                    }
                    else {
                       
                        columnBaseSellingPrice.hideColumn();
                        columnBaseSellingValue.hideColumn();
                        columnCostPrice.showColumn();
                        columnSellingPrice.showColumn();
                        columnSellingValue.showColumn();
                    }

                    columnCostPrice.hideColumn();
                    if (this.consignmentTypeDefaults && this.consignmentTypeDefaults.UseExportCostModels && this.allowCostPriceOverride){
                        columnCostPrice.showColumn();
                    }
                }

                gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.COLUMN);
            }


            loadCommercialInvoice() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.com'
                },
                    () => {
                        return this.commercialInvoiceService.get(this.$state.params['comId'], this.$state.params['conId'], null).get((result) => {
                            this.CommercialInvoice = result;              

                            this.comNumber = this.CommercialInvoice.Number;
                            this.captureInBaseCurrency = this.CommercialInvoice.IsBaseCurrency;
                            
                            if (this.CommercialInvoice)
                            {
                                this.gvwSundry.data = this.CommercialInvoice.CommercialInvoiceSundryItemList;
                                this.gvwSku.data = this.CommercialInvoice.CommercialInvoiceLineSKUDisplayList;                            

                                this.gvwComDeliveryInstructions.data = this.CommercialInvoice.DeliveryInstructionsList;
                            }

                            this.$timeout(() => {
                                if (this.apiSkuList) {
                                    this.showHideSKUColumn(this.apiSkuList);

                                    var sortState = this.getSortState();
          

                                    if (sortState) {
                                        const columnName = sortState.columnName;
                                        const direction = sortState.direction;                                       
                                        // Find the column object that matches the columnName
                                        const columnToSort = this.apiSkuList.grid.columns.find((column: any) => column.field === columnName);
                                      
                                        if (columnToSort) {
                                          // Use the found column object to sort
                                          this.apiSkuList.grid.sortColumn(columnToSort, direction, false);
                                        } else {
                                          console.error(`Column '${columnName}' not found in the grid.`);
                                        }
                                      }

                                    if (this.selectedSKUCommercialInvoiceLineId) {
                                        var index = _.findIndex(this.gvwSku.data, (o) => { return o.CommercialInvoiceLineId === this.selectedSKUCommercialInvoiceLineId });
                                        this.apiSkuList.selection.selectRow(this.gvwSku.data[index]);
                                        this.apiSkuList.core.scrollTo(this.gvwSku.data[index],null);
                                    }

                                }
                            });

                            this.loadConsignmentTypeDefaults();
                            this.loadEntityAdditionalSettings();
                            this.getAllowPriceOverrideRight();
                            this.incotermEditMode = false;
                            this.getAllowIncotermOverrideRight();
                            this.loadALCApportionment();
                            
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            loadEntityAdditionalSettings() {
                return  this.entityService.getEntityAdditionalSettings().get({ entityId: this.CommercialInvoice.OwnerEntity.Id}, (result: interfaces.applicationcore.EntityAdditionalSettings) => {
                        this.EntityAdditionalSettings = result; 
                        if (this.EntityAdditionalSettings.azureAIDocumentIntelligence && this.EntityAdditionalSettings.azureAIDocumentIntelligence.apiKey){
                            //if we have an apiKey setup for this entity, then we can enable the OCR Button
                            this.EnableDocumentToExcel = true;  
                        }
                });
            }

            loadConsignmentTypeDefaults() {
                return this.consignmentService.GetConsignmentTypeDefaultsByConsignment(this.$state.params['conId']).get((data: interfaces.consignment.ConsignmentTypeDefaults) => {
                    this.consignmentTypeDefaults = data;
                    this.$timeout(() => {
                        if (this.apiSkuList) {
                            this.showHideSKUColumn(this.apiSkuList);
                        }
                    });
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise
            }

            getAllowPriceOverrideRight() {
                this.allowCostPriceOverride = false;
                return this.menuService.getGTSConnectMenuItem(575).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.allowCostPriceOverride = true;
                    }
                }).$promise;
            }

            getAllowIncotermOverrideRight() {
                this.incotermOverride = false;
                return this.menuService.getGTSConnectMenuItem(688).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.incotermOverride = true;
                    }
                }).$promise;
            }            

            loadCurrencies() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(currencyList);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            incotermOverrideClick(){
                this.incotermEditMode = true;
            }

            
            incoTermChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.CommercialInvoice.TermsOfDelivery = null;
                if (model) {
                    this.CommercialInvoice.Incoterm = model;
                    this.termsOfDeliveryList = [];
                    this.loadTermsOfDeliveries();
                }
            }


            loadIncoterms() {
                return this.incotermService.getDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }


            loadTermsOfDeliveries() {
                var defered = this.$q.defer();
                if (!this.CommercialInvoice || !this.CommercialInvoice.Incoterm) {
                    defered.resolve([]);
                    return defered.promise;
                }
                
                this.termsOfDeliveryService.getDropdownList().query({ ownerEntityId: this.CommercialInvoice.OwnerEntity.Id, incoTermId: this.CommercialInvoice.Incoterm.Id }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadSuppliers(searchText: string) {
                var defered = this.$q.defer();

                if (!this.CommercialInvoice) {
                    defered.resolve([]);
                    return defered.promise;
                }
                
                if (this.$rootScope.Entity) {
                    this.supplierService.getSupplierDropdown(this.$rootScope.Entity.Id, searchText).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(list);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defered.resolve([]);
                    });
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        this.supplierService.getSupplierDropdown(resultEntity.Id, searchText).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            defered.resolve(list);
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            defered.resolve([]);
                        });
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }

                return defered.promise;
            }

            loadCustomers(searchText: string) {
                var defered = this.$q.defer();

                if (!this.CommercialInvoice) {
                    defered.resolve([]);
                    return defered.promise;
                }
                
                if (this.$rootScope.Entity) {
                    this.customerService.getDropdownList(this.$rootScope.Entity.Id, searchText).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(list);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defered.resolve([]);
                    });
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        this.customerService.getDropdownList(resultEntity.Id, searchText).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            defered.resolve(list);
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            defered.resolve([]);
                        });
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }

                return defered.promise;
            }

            loadBanks() {
                var defered = this.$q.defer();

                if (!this.CommercialInvoice) {
                    defered.resolve([]);
                    return defered.promise;
                }
                
                if (this.$rootScope.Entity) {
                    this.bankService.getDropdownList(this.$rootScope.Entity.Id, "").query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(list);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defered.resolve([]);
                    });
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        this.bankService.getDropdownList(resultEntity.Id, "").query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            defered.resolve(list);
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            defered.resolve([]);
                        });
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }

                return defered.promise;
            }

            downloadToExcel() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.com'
                },
                    () => {
                        this.commercialInvoiceService.GetCommercialInvoiceLineSKUDisplayListExcel(this.$state.params['comId']).then(() => {
                        }, (data) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                        });
                    });
            }

            UploadFromExcel(){
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                        <div class="modal-header" style="white-space: nowrap;">
                            <h3 class="modal-title" id="modal-title">Upload from Excel <i class="fa fa-question-circle" uib-tooltip='Use this option to add, update or remove lines from the invoice using an Excel Spreadsheet. The format of the spreadsheet is the same as the Download to Excel functionality. If you tick the option Create Products, GTS will automatically create a product from the Excel details if none exists.' tooltip-placement="bottom"></i></h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="cominv.excelupload">
                            <form name="cominvexcelupload">
                                <div class="gts-padding">
                                    <gts-message-display messages="Messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
						                            <label>Create Products?</label>
					                            </div>
					                            <div class="col-md-6">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox"  ng-disabled="uploadExcelCtrl.disableSubmit" 
                                                            ng-model="uploadExcelCtrl.createProducts" 
                                                            name="createProducts">
                                                    </p>
					                            </div>
				                            </div>
			                            </div>

                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
						                            <label>Replace Invoice?</label>
					                            </div>
					                            <div class="col-md-6">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="uploadExcelCtrl.disableSubmit"  
                                                            ng-model="uploadExcelCtrl.replaceInvoice" 
                                                            name="SendApprovalRejectUserAlert"> &nbsp;<i class="fa fa-question-circle" uib-tooltip='if ticked, any lines missing from the spreadsheet will be deleted. If not ticked lines will only be added or amended' tooltip-placement="top"></i>
                                                    </p>
                                                    
					                            </div>
				                            </div>
                                        </div>  

                                        <div class="row">
                                            <div class="row vertical-align">
                                                <div class="col-md-8">
                                                    <label class="fileUpload btn btn-primary file-upload-btn-primary" for="docFileToUpload" ng-disabled="uploadExcelCtrl.disableSubmit">
                                                        <span>Select File</span>
                                                        <input name="docFileToUpload" ng-disabled="uploadExcelCtrl.disableSubmit" class="upload" type="file" id="docFileToUpload" ng-model="uploadExcelCtrl.repositoryFile" base-sixty-four-input accept=".xlsx" />
                                                    </label>
                                                    <span>{{uploadExcelCtrl.repositoryFile.filename}}</span>
                                                </div>
                                            </div>
                                        </div>   
                                    </div>
                                    <div class="modal-footer"> 
                                        <button class="btn btn-primary" type="button"  ng-disabled="!uploadExcelCtrl.repositoryFile || uploadExcelCtrl.disableSubmit" ng-click="uploadExcelCtrl.upload()">Submit</button>
                                        <button class="btn btn-info" type="button" ng-disabled="uploadExcelCtrl.disableSubmit" ng-click="uploadExcelCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class uploadExcelCtrl {

                        messages: interfaces.applicationcore.IMessage[];

                        createProducts: boolean = false;
                        replaceInvoice: boolean = false;
                        disableSubmit: boolean = false;
                        repositoryFile: interfaces.applicationcore.IRepositoryFile;

                        commercialInvoiceExcelData: interfaces.logistics.ICommercialInvoiceExcelData;

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private commercialInvoiceService: interfaces.logistics.ICommercialInvoiceService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                            public bsLoadingOverlayService,
                            private comId : number
                        ) {
                        
                        }

                 

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }

                        upload() {
                            this.disableSubmit = true;
                             this.commercialInvoiceExcelData = <interfaces.logistics.ICommercialInvoiceExcelData>{
                                comId: this.comId,
                                createProduct:this.createProducts,
                                replaceInvoice: this.replaceInvoice,
                                repositoryFile:this.repositoryFile
                             };

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'cominv.excelupload'
                            },
                                () => {

                                    return this.commercialInvoiceService.uploadFromExcel().save(this.commercialInvoiceExcelData, (result) => {
                                        this.generalService.displayMessageHandler(result);
                                        if (!result.HasErrorMessage) {
                                            this.$uibModalInstance.close(true);
                                        }

                                        this.$uibModalInstance.close(true);
                                    }, (errorResult) => {
                                        this.generalService.displayMessageHandler(errorResult.data);

                                        if (errorResult.data.Messages) {
                                            this.messages = [];
                                        }

                                        angular.forEach(errorResult.data.Messages, (o) => {
                                            angular.forEach(o.MessageItems, (n) => {
                                                this.messages.push(n);
                                            });
                                        });
                                        }).$promise;

                                });
                        }
                    },
                    controllerAs: "uploadExcelCtrl",
                    resolve: { comId : this.$state.params['comId']
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadCommercialInvoice();
                    }
                });
            }

            DocumentToExcel(){
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                        <div class="modal-header" style="white-space: nowrap;">
                            <h3 class="modal-title" id="modal-title">Document To Excel (OCR) <i class="fa fa-question-circle" uib-tooltip='Use this option to use a prebuilt invoice OCR template, to generate a GTS Commercial Invoice Excel document.' tooltip-placement="bottom"></i></h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="cominv.ocr">
                            <form name="cominvexcelupload">
                                <div class="gts-padding">
                                    <gts-message-display messages="documentToExcelCtrl.messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
						                            <label>Template</label>
					                            </div>
					                            <div class="col-md-6">
                                                <form name="inputForm">
                                                    <gts-dropdown name="template" ng-model="documentToExcelCtrl.template" form="inputForm" load-data="documentToExcelCtrl.getAzureOCRTemplateList()" ></gts-dropdown>
                                                </form>
					                            </div>
				                            </div>
			                            </div>

                                        <div class="row">
                                            <div class="row vertical-align">
                                                <div class="col-md-10">
                                                    <label class="fileUpload btn btn-primary file-upload-btn-primary" for="docFileToUpload">
                                                        <span>Select File(s)</span>
                                                        <input name="docFileToUpload" class="upload" multiple  type="file" id="docFileToUpload"  ng-change="documentToExcelCtrl.onFileSelect()" ng-model="documentToExcelCtrl.files" base-sixty-four-input accept=".jpg, .jpeg, .png, .pdf, .jfif, .pjpeg, .pjp, .tiff, .tif" />
                                                    </label>
                                                 

                                                    <table class="table table-hover" ng-if="documentToExcelCtrl.files.length>0">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>File Name</th>
                                                                <th>File Size (mb)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr ng-repeat="file in documentToExcelCtrl.files">
                                                                <td><div  ng-click="documentToExcelCtrl.removeFile(file)"> <i class="fa fa-times"></i></div></td>
                                                                <td>{{file.filename}}</td>
                                                                <td>{{file.filesize / 1000000 | number: 2}}</td>    
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>   
                                    </div>
                                    <div class="modal-footer"> 
                                        <button class="btn btn-primary" type="button"  ng-disabled="documentToExcelCtrl.disableSubmit || !documentToExcelCtrl.template || documentToExcelCtrl.files.length === 0" ng-click="documentToExcelCtrl.upload()">Submit</button>
                                        <button class="btn btn-info" type="button"  ng-click="documentToExcelCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class documentToExcelCtrl {

                        messages: interfaces.applicationcore.IMessage[] = [];

                        createProducts: boolean = false;
                        replaceInvoice: boolean = false;
                        disableSubmit: boolean = true;
                        files: interfaces.applicationcore.IRepositoryFile[] = [];
                        maxFileSizeInMB = 1;
                        template: interfaces.applicationcore.IDropdownModel;

                        ocrToInvoiceFileData: interfaces.logistics.OCRToInvoiceFileData;

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private commercialInvoiceService: interfaces.logistics.ICommercialInvoiceService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                            public bsLoadingOverlayService,
                            public ENV: interfaces.applicationcore.serverConfig,
                            private comId : number,
                            private entityId: number
                        ) {
                            if (this.ENV.MAXIMUM_FILE_SIZE_MB){
                                this.maxFileSizeInMB = this.ENV.MAXIMUM_FILE_SIZE_MB;
                            }
                            this.getAzureOCRTemplateList();
                        }
                 

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }

                        getAzureOCRTemplateList(){
                            return this.entityService.getAzureOCRTemplateList(this.entityId).query(
                                (result: interfaces.applicationcore.IDropdownModel[]) => {
                                    //if result only has 1 entry then set it as the default template
                                    if (result.length == 1){
                                        this.template = result[0];
                                    }
                                
                                }, (failureData) => {
                                }).$promise;
                        }

                        onFileSelect() {
                            this.verifyFiles();
                        }

                        verifyFiles(){
                            this.disableSubmit = false;
                            this.messages = [];
                            for (const file of this.files) {
                                if (file.filesize/ 1000000 > this.maxFileSizeInMB) {
                                  this.disableSubmit = true;

                                  var errorMessage = <interfaces.applicationcore.IMessage>{
                                    ID: "0",
                                    MessageType: 1,
                                    LocaleDescription:'File '+file.filename+ ' exceeds maximum file size of '+ this.maxFileSizeInMB + ' mb',
                                    TypeEnum: 1,
                                    MessageItems: <interfaces.applicationcore.IMessageItem[]>[{
                                        LocaleDescription: "'File '+file.name+ ' exceeds maximum file size of '+ this.maxFileSizeInMB + ' mb' ",
                                        TypeEnum: 1
                                    }]
        
                               };                                  
                                  this.messages.push(errorMessage);
                                }
                              }
                        }

                        removeFile(file) {
                            const index = this.files.indexOf(file);
                            if (index !== -1) {
                              this.files.splice(index, 1); // Remove the file from the array
                            }

                            this.verifyFiles();
                          }

                        upload() {
                            this.disableSubmit = true;
                             this.ocrToInvoiceFileData = <interfaces.logistics.OCRToInvoiceFileData>{
                                comId: this.comId,
                                templateName: this.template.Display,
                                files: this.files
                             };

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'cominv.ocr'
                            },
                                () => {


                                  return  this.commercialInvoiceService.processDocumentsToExcel(this.ocrToInvoiceFileData).then((data) => {

                                    this.disableSubmit = false;
                                    this.$uibModalInstance.close(false);

                                    }, (data) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                                        
                                    });                                   

                                });
                        }
                    },
                    controllerAs: "documentToExcelCtrl",
                    resolve: { comId : this.$state.params['comId'], entityId : this.CommercialInvoice.OwnerEntity.Id
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadCommercialInvoice();
                    }
                });
            }            

            //Sundry Items
            public registerSundryApi(gridApi: uiGrid.IGridApi) {
                this.apiSundryList = gridApi;

                this.gvwSundry.data = this.CommercialInvoice.CommercialInvoiceSundryItemList;

                this.apiSundryList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiSundryList.selection.selectRow(gridSelection.row.entity);
                });
            }
            
            gvwSundry: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                cellEditableCondition: () => { return this.CommercialInvoice.LockdownType < 3 },
                onRegisterApi: (gridApi) => { this.registerSundryApi(gridApi); },
                columnDefs: [{
                    name: "EDIT",
                    field: "Id",
                    displayName: "",
                    enableFiltering: false,
                    width: 33,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-disabled="grid.appScope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType >= 1" ng-click="grid.appScope.commercialInvoiceUpdateCtrl.editSundryItem(MODEL_COL_FIELD)">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-disabled="grid.appScope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType >= 1" ng-click="grid.appScope.commercialInvoiceUpdateCtrl.addInlineSundryItem()" >
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>`
                }, {
                    name: "SUNITM",
                    displayName: "* Sundry Item",
                    field: "SundryItem",
                    enableFiltering: true,
                        enableCellEdit: true,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                    enableCellEditOnFocus: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.commercialInvoiceUpdateCtrl.loadSundryItems(searchText)" ng-change="grid.appScope.commercialInvoiceUpdateCtrl.sundryItemChanged(model, row.entity)" required="true" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiSundryList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "SUNDESC",
                    displayName: "Sundry Item Description",
                    field: "SundryItemDescription",
                    width: 300,
                        enableCellEdit: true,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-sunDesc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "COSTEL",
                    displayName: "Cost Model Element",
                    field: "CostModelElement",
                    width: 175,
                        enableCellEdit: false,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "QTY",
                    displayName: "* Quantity",
                    field: "Quantity",
                    width: 80,
                        enableCellEdit: true,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                    enableCellEditOnFocus: true,
                    cellFilter: "number: 2",
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="{{MODEL_COL_FIELD.$$hashKey}}-qty" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" ng-change="grid.appScope.commercialInvoiceUpdateCtrl.quantityChanged(row.entity)" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "SELLPRICE",
                    displayName: "* Selling Price",
                    field: "SellingPrice",
                    width: 150,
                        enableCellEdit: true,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                    enableCellEditOnFocus: true,
                    cellFilter: "number: 2",
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="{{MODEL_COL_FIELD.$$hashKey}}-sellprice" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" ng-change="grid.appScope.commercialInvoiceUpdateCtrl.sellingPriceChanged(row.entity)" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }" step="0.01"/></div>
                            </div>`
                }, {
                    name: "SELLVALUE",
                    displayName: "Selling Value",
                    field: "SellingValue",
                    width: 150,
                        enableCellEdit: false,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                    enableCellEditOnFocus: false,
                    cellFilter: "number: 2",
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }" step="0.01"/></div>
                            </div>`
                }, {
                    name: "VATVALUE",
                    displayName: "VAT Value",
                    field: "VATValue",
                    width: 150,
                        enableCellEdit: false,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                    enableCellEditOnFocus: false,
                    cellFilter: "number: 2",
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }" step="0.01"/></div>
                            </div>`
                }, {
                    name: "CURCODE",
                    displayName: "Currency Code",
                    field: "CurrencyCode",
                    width: 150,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "ISDUT",
                    displayName: "Is Dutiable",
                    field: "IsDutiable",
                    width: 175,
                    enableCellEdit: false,
                    cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                    enableCellEditOnFocus: false,
                    cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" disabled>',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }, {
                    name: "INCLPRICE",
                    displayName: "Is Included In Price",
                    field: "IsIncludingInPrice",
                    width: 175,
                    enableCellEdit: false,
                    cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                    enableCellEditOnFocus: false,
                    cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" disabled>',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                }]
            };

            addInlineSundryItem() {
                this.CommercialInvoice.CommercialInvoiceSundryItemList.push(<interfaces.logistics.ICommercialInvoiceSundryItemDisplay>{
                    Quantity: 1,
                    SellingValue: 0,
                    SellingPrice: 0,
                    VATValue: 0
                });
            }

            loadSundryItems(searchText: string) {
                if (this.CommercialInvoice) {
                    return this.sundryItemService.getDropdownList(this.CommercialInvoice.OwnerEntity.Id,this.isInbound ,searchText).query({ ownerEntityId: this.CommercialInvoice.OwnerEntity.Id, searchText: searchText }, () => {
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            generateSundryItems_Click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.com'
                }, () => {
                    return this.refreshSundryItems().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.loadCommercialInvoice();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });
            };
            
            refreshSundryItems() {
                return this.commercialInvoiceService.refreshSundryItems().save({
                    comId: this.CommercialInvoice.Id,
                    conId: this.CommercialInvoice.ConsignmentId
                }, () => {
                    this.loadCommercialInvoice();    
                }).$promise;
            };
            
            removeSundryLine_Click() {
                var selectedRow = <interfaces.consignment.IConsignmentDetailDisplay>this.apiSundryList.selection.getSelectedRows()[0];                   
                if (selectedRow && selectedRow.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update.com'
                    }, () => {
                        return this.commercialInvoiceSundryItemService.delete(selectedRow.Id).delete((result) => {
                            this.generalService.displayMessageHandler(result);
                            if (!result.HasErrorMessage) {

                                _.remove(this.gvwSundry.data, (o) => {
                                    return o === selectedRow;
                                });

                                this.$timeout(() => {
                                    return this.loadCommercialInvoice();
                                });
                            }

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                } else if (selectedRow) {
                    _.remove(this.gvwSundry.data, (o) => {
                        return o === selectedRow;
                    });
                }
            }

            addSundryItem_Click()
            {
                if (this.isManifest)
                {
                    if (this.isInbound)
                        this.$state.go("auth.Manifests.Update.ConsignmentImport.CommercialInvoice.SundryItem", { cisId: 0 });
                    else
                        this.$state.go("auth.Manifests.Update.ConsignmentExport.CommercialInvoice.SundryItem", { cisId: 0 });
                }
                else
                {
                    if (this.isInbound) {
                        this.$state.go("auth.ConsignmentImports.Update.CommercialInvoice.SundryItem", { cisId: 0 });
                    } else {
                        this.$state.go("auth.ConsignmentExports.Update.CommercialInvoice.SundryItem", { cisId: 0 });
                    }
                }
            }

            editSundryItem(cisId: number) {
                if (this.isManifest && cisId) {
                    if (this.isInbound)
                        this.$state.go("auth.Manifests.Update.ConsignmentImport.CommercialInvoice.SundryItem", { cisId: cisId });
                    else
                        this.$state.go("auth.Manifests.Update.ConsignmentExport.CommercialInvoice.SundryItem", { cisId: cisId });
                }
                else {
                    if (this.isInbound) {
                        this.$state.go("auth.ConsignmentImports.Update.CommercialInvoice.SundryItem", { cisId: cisId });
                    } else {
                        this.$state.go("auth.ConsignmentExports.Update.CommercialInvoice.SundryItem", { cisId: cisId });
                    }
                }
            }

            sundryItemChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.logistics.ICommercialInvoiceSundryItemDisplay) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update.com'
                    },
                        () => {
                            return this.sundryItemService.getDefault().get({ sunId: model.Id, countryId: this.CommercialInvoice.SupplierCountryId }, (result: interfaces.master.ISundryItemDefault) => {
                                entity.IsDutiable = result.Dutiable;
                                entity.IsIncludingInPrice = result.IsIncludedInPrice;
                                entity.VATRate = result.VatRateRate;

                                if (result.CostModelElement)
                                    entity.CostModelElement = result.CostModelElement.Display;

                                entity.SundryItemDescription = result.Description;

                                entity.Quantity = 1;
                                entity.SellingPrice = 0;
                                entity.VATValue = 0;
                                entity.SellingValue = 0;

                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }

            quantityChanged(entity: interfaces.logistics.ICommercialInvoiceSundryItemDisplay) {
                this.calculateVat(entity);
            }

            sellingPriceChanged(entity: interfaces.logistics.ICommercialInvoiceSundryItemDisplay) {
                this.calculateVat(entity);
            }

            calculateVat(entity: interfaces.logistics.ICommercialInvoiceSundryItemDisplay) {
                entity.SellingValue = Number((Math.round(entity.Quantity * entity.SellingPrice).toFixed(2)));
                entity.VATValue = Number((Math.round(entity.SellingValue * entity.VATRate) / 100));
            }

            //Sales Orders
            //gvwComSalesOrders
            public registerComSalesOrdersApi(gridApi: uiGrid.IGridApi) {
                this.apiComSalesOrdersList = gridApi;

                this.apiComSalesOrdersList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiComSalesOrdersList.selection.selectRow(gridSelection.row.entity);
                });
                

                this.$timeout(() => {
                    if (this.CommercialInvoice)
                    {
                        this.gvwComSalesOrders.data = this.CommercialInvoice.SalesOrderList;
                    }
                });
            }

            //Commercial Invoice Sales Order List
            gvwComSalesOrders: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerComSalesOrdersApi(gridApi); },
                columnDefs: [{
                    name: "ID",
                    displayName: "",
                    field: "SalesOrderId",
                    enableSorting: false,
                    enableColumnMenu: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `
                    <div class="GridButton">
                        <div class="gts-padding">
                            <button type="button"  ng-click="grid.appScope.commercialInvoiceUpdateCtrl.displayDeliveryStatus(row.entity)"  class="btn btn-info btn-sm">
                                Delivery Status
                            </button>
                        </div>
                    </div>`,
                    width: 110,
                }, {
                    name: 'SALESORDERNUMBER',
                    displayName: 'Sales Order Number',
                    field: 'Number',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents"><a href="{{grid.appScope.commercialInvoiceUpdateCtrl.ENV.ROOT_URL}}/ApplicationCore/GTS/index.html#!/SalesOrders/SalesOrders/Update/{{row.entity.SalesOrderId}}" target="_blank">{{MODEL_COL_FIELD}}</a></div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'BUYERSREFERENCE',
                    displayName: 'Buyers Reference',
                    field: 'BuyersReference',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'SUPPLIER',
                    displayName: 'Supplier',
                    field: 'SupplierName',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'ROUTECODE',
                    displayName: 'Route Code',
                    field: 'RouteCode',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'TRANSPORTMODEDESCRIPTION',
                    displayName: 'Transport Mode',
                    field: 'TransportModeDescription',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'COUNTRYOFORIGINDESCRIPTION',
                    displayName: 'Country Of Origin',
                    field: 'CountryOfOriginDescription',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'COUNTRYOFDESTINATIONDESCRIPTION',
                    displayName: 'Country Of Destination',
                    field: 'CountryOfDestinationDescription',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'ESTIMATEDTIMEOFDEPARTURE',
                    displayName: 'Estimated Time of Departure',
                    field: 'EstimatedTimeofDeparture',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'ESTIMATEDTIMEOFARRIVAL',
                    displayName: 'Estimated Time of Arrival',
                    field: 'EstimatedTimeofArrival',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'TOTALAVAILABILITYQUANTITY',
                    displayName: 'Total Available Quantity',
                    field: 'TotalAvailableQuantity',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | number:0}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'CONSIGNEECODE',
                    displayName: 'Consignee Code',
                    field: 'ConsigneeCode',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'CUBE',
                    displayName: 'Cube',
                    field: 'Cube',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'CUSTOMERCODE',
                    displayName: 'Customer Code',
                    field: 'CustomerCode',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'ENTITYCODE',
                    displayName: 'Entity Code',
                    field: 'EntityCode',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'GROSSWEIGHT',
                    displayName: 'Gross Weight',
                    field: 'GrossWeight',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'NETTWEIGHT',
                    displayName: 'Nett Weight',
                    field: 'NettWeight',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'TERMSOFPAYMENT',
                    displayName: 'Terms Of Payment',
                    field: 'TermsOfPayment',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'TOTALPACKAGES',
                    displayName: 'Total Packages',
                    field: 'TotalPackages',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }]
            };
            
            displayDeliveryStatus(entity: interfaces.sales.ISalesOrderForCommercialInvoice)
            {
                //Get delivery status
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content">
                            <div class="gts-padding">
                                <div class="modal-body" id="modal-body">
                                    <div class="row vertical-align">
                                        <div class="col-md-4">
                                            <opt></opt>
                                            <label>Delivery Note Reference</label>
                                        </div>
                                        <div class="col-md-8">
                                            {{SalesOrder.DeliveryNoteReference}}
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-4">
                                            <opt></opt>
                                            <label>Delivery Note Date</label>
                                        </div>
                                        <div class="col-md-8">
                                            {{SalesOrder.DeliveryNoteDate | momentDateFilter: 'YYYY/MM/DD'}}
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-4">
                                            <opt></opt>
                                            <label>Financial Invoice Number</label>
                                        </div>
                                        <div class="col-md-8">
                                            {{SalesOrder.FinancialInvoiceNumber}}
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-4">
                                            <opt></opt>
                                            <label>Financial Invoice Date</label>
                                        </div>
                                        <div class="col-md-8">
                                            {{SalesOrder.FinancialInvoiceDate | momentDateFilter: 'YYYY/MM/DD'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-info" ng-click="ok()">Close</button>
                                </div>
                            </div>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                    ) => {
                        $scope.SalesOrder = entity;

                        $scope.ok = () => {
                            $uibModalInstance.dismiss();
                        }
                    },
                    controllerAs: "deliveryStatusCtrl",
                    size: "md",
                    resolve: {
                    }
                });
            }

            //Delivery Instructions
            public registerComDeliveryInstructionsApi(gridApi: uiGrid.IGridApi) {
                this.apiComDeliveryInstructionsList = gridApi;

                this.apiComDeliveryInstructionsList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiComDeliveryInstructionsList.selection.selectRow(gridSelection.row.entity);
                });

                this.$timeout(() => {
                    if (this.CommercialInvoice) {
                        this.gvwComDeliveryInstructions.data = this.CommercialInvoice.DeliveryInstructionsList;
                    }
                });
            }

            //Commercial Invoice Delivery Instructions List
            gvwComDeliveryInstructions: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerComDeliveryInstructionsApi(gridApi); },
                columnDefs: [{
                    name: 'CODE',
                    displayName: 'Order Number',
                    field: 'Code',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents"><a href="/ApplicationCore/GTS/index.html#!/PurchaseOrders/Update/{{row.entity.PurchaseOrderId}}" target="_blank">{{MODEL_COL_FIELD}}</a></div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'COUNTRYOFORIGIN',
                    displayName: 'Country Of Origin',
                    field: 'CountryOfOrigin',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'DICODE',
                    displayName: 'DI Number',
                    field: 'DeliveryInstructionCode',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents"><a href="{{grid.appScope.commercialInvoiceUpdateCtrl.ENV.ROOT_URL}}/ApplicationCore/GTS/index.html#!/PurchaseOrders/Update/{{row.entity.PurchaseOrderId}}/di/{{row.entity.DeliveryInstructionId}}" target="_blank">{{MODEL_COL_FIELD}}</a></div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'DESTINATION',
                    displayName: 'Destination',
                    field: 'Destination',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'DESTINATIONLOCATION',
                    displayName: 'Destination Location',
                    field: 'DestinationLocation',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'WAREHOUSE',
                    displayName: 'Warehouse',
                    field: 'Warehouse',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'DELIVERYWINDOWFROM',
                    displayName: 'Delivery Window From',
                    field: 'NotBeforeDate',
                    enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'DELIVERYWINDOWTO',
                    displayName: 'Delivery Window To',
                    field: 'NotAfterDate',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'TRANSPORTMODEDESCRIPTION',
                    displayName: 'Transport Mode',
                    field: 'TransportModeDescription',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'SHIPPEDBY',
                    displayName: 'Shipped By',
                    field: 'ShippedByDate',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    enableSorting: false,
                    width: 250
                }, {
                    name: 'SHIPPEDONBOARD',
                    displayName: 'Shipped On Board',
                    field: 'ShippedOnBoardDate',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    enableSorting: false,
                    width: 250
                }]
            };

            SaveCommercialInvoice() {
                var defered = this.$q.defer();

                if (this.apiSkuList) {
                    this.CommercialInvoice.CommercialInvoiceLineSKUDisplayList = _.map(this.apiSkuList.rowEdit.getDirtyRows(), 'entity');
                } else {
                    this.CommercialInvoice.CommercialInvoiceLineSKUDisplayList = [];
                }

                if (this.apiSundryList) {
                    this.CommercialInvoice.CommercialInvoiceSundryItemList = _.map(this.apiSundryList.rowEdit.getDirtyRows(), 'entity');
                } else {
                    this.CommercialInvoice.CommercialInvoiceSundryItemList = [];
                }

                if (this.apiComSalesOrdersList) {
                    this.CommercialInvoice.SalesOrderList = _.map(this.apiComSalesOrdersList.rowEdit.getDirtyRows(), 'entity');
                } else {
                    this.CommercialInvoice.SalesOrderList = [];
                }

                this.commercialInvoiceService.save().save({}, this.CommercialInvoice,
                    (result: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(result);
                        defered.resolve(result);

                        if (this.apiSundryList) {
                            this.apiSundryList.rowEdit.setRowsClean(this.CommercialInvoice.CommercialInvoiceSundryItemList);
                        }

                        if (this.apiComSalesOrdersList) {
                            this.apiComSalesOrdersList.rowEdit.setRowsClean(this.CommercialInvoice.SalesOrderList);
                        }

                        if (this.apiSkuList) {
                            this.apiSkuList.rowEdit.setRowsClean(this.CommercialInvoice.CommercialInvoiceLineSKUDisplayList);
                        }

                    }, (failureMessage) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                        defered.reject(failureMessage);
                    });

                return defered.promise;
            }

            Save() {
                this.generalService.displayConfirmationBox("Confirm", "Save Commercial Invoice?").then((result) => {

                    if (result) {
                        this.$timeout(() => {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'con.update.com'
                            },
                                () => {
                                    return this.SaveCommercialInvoice().then((data) => {
                                        this.$anchorScroll("topAnchor");
                                        this.loadCommercialInvoice();
                                    }, (data) => {
                                        this.$anchorScroll("topAnchor");
                                    });
                                });
                        });
                        
                    }
                });
            }

            SaveClose() {
                this.generalService.displayConfirmationBox("Confirm", "Save Commercial Invoice?").then((result) => {

                    if (result) {
                        this.$timeout(() => {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'con.update.com'
                            },
                                () => {
                                    return this.SaveCommercialInvoice().then((data) => {
                                        this.$state.go("^");

                                    }, (failureData) => {
                                        this.$anchorScroll("topAnchor");
                                    });
                                });
                        });
                    }
                });
            }

            Close() {
                this.$state.go("^");
            }
                        
            generateFECNumber_Click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.com'
                }, () => {
                    return this.generateFECNumber().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.loadCommercialInvoice();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });

            };
            
            generateFECNumber() {
                return this.commercialInvoiceService.generateFECNumber().save({
                    id: this.CommercialInvoice.Id
                }).$promise;
            };

            //Comments Section
            defaultComments_Click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.com'
                }, () => {
                    return this.defaultComments().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.loadCommercialInvoice();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });
            };

            defaultComments() {
                return this.commercialInvoiceService.refreshComments().save({
                    conId: this.CommercialInvoice.ConsignmentId,
                    comId: this.CommercialInvoice.Id
                }).$promise;
            };

            //Instruction Section
            defaultInstructions_Click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.com'
                }, () => {
                    return this.defaultInstructions().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.loadCommercialInvoice();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });
            };

            defaultInstructions() {
                return this.commercialInvoiceService.refreshInstructions().save({
                    comId: this.CommercialInvoice.Id
                }).$promise;
            };


            updateCommercialInvoiceDate(data: interfaces.logistics.ICommercialInvoiceRecalculateExchangeRate) {
                var defered = this.$q.defer();

                this.commercialInvoiceService.updateCommercialInvoiceDate().save(data,
                    (result: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(result);
                        defered.resolve(result);

                    }, (failureMessage) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                        defered.reject(failureMessage);
                    });

                return defered.promise;
            }
            
            displayUpdateInvoiceDateConfirmationBox(entity: interfaces.logistics.ICommercialInvoiceRecalculateExchangeRate) {
                
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <form name="InvoiceDateUpdate">
                        <div class="modal-content">
                            <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">{{CommercialInvoiceRecalculateExchangeRate.CommercialInvoice.IsInbound?'Update Commercial Invoice Number & Date':'Update Commercial Invoice Date'}}</h3>
                            </div>
                            <div class="gts-padding">
                                <div class="modal-body" id="modal-body">
                                    <div class="row vertical-align">
                                        <div class="col-md-12">
                                            Are you sure you want to update the Commercial Invoice Date? If you select Recalculate Exchange Rate, the invoice exchange rate date will be updated with the selected invoice date and the exchange rate recalculated.
                                        </div>
                                    </div>
                                    <div class="row vertical-align"  ng-if="CommercialInvoiceRecalculateExchangeRate.CommercialInvoice.IsInbound">
                                    <div class="col-md-6">
                                        <req></req>
                                        <label>Commercial Invoice Number</label>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="input-group-sm has-feedback" ng-class="{'has-error': CommercialInvoiceRecalculateExchangeRate.NewInvoiceNumber.$invalid}">
                                            <input type="text" ng-required="true" name="commercialInvoiceNumber" ng-model="CommercialInvoiceRecalculateExchangeRate.NewInvoiceNumber" class="form-control" />
                                        </p>
                                    </div>
                                </div>                                    
                                    <div class="row vertical-align">
                                        <div class="col-md-6">
                                            <req></req>
                                            <label>Commercial Invoice Date</label>
                                        </div>
                                        <div class="col-md-6">
                                            <gts-date ng-required="true" format="yyyy/MM/dd" ng-model="CommercialInvoiceRecalculateExchangeRate.NewDate" name="CommercialInvoiceDate"></gts-date>
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-6">
                                            <opt></opt>
                                            <label>Recalculate Exchange Rate?</label>
                                        </div>
                                        <div class="col-md-6">
                                            <input type="checkbox" ng-model="CommercialInvoiceRecalculateExchangeRate.RecalculateExchangeRate" name="RecalculateExchangeRate">
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-6">
                                            <req></req>
                                            <label>Comments</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p class="input-group input-group-sm has-feedback" ng-class="{'has-error': InvoiceDateUpdate.Comments.$invalid}">
                                            <textarea rows="3" required name="Comments" ng-model="CommercialInvoiceRecalculateExchangeRate.Comments" class="form-control"></textarea>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" ng-disabled="InvoiceDateUpdate.$invalid" type="button" ng-click="ok()">OK</button>
                                    <button class="btn btn-info" type="button" ng-click="cancel()">Cancel</button>
                                </div>
                            </div>
                        </div>
                        </form>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                    ) => {

                        $scope.CommercialInvoiceRecalculateExchangeRate = entity;
                        $scope.CommercialInvoiceRecalculateExchangeRate.NewInvoiceNumber =  $scope.CommercialInvoiceRecalculateExchangeRate.CommercialInvoice.Number;

                        $scope.ok = function () {
                            $uibModalInstance.close($scope.CommercialInvoiceRecalculateExchangeRate);
                        };

                        $scope.cancel = function () {
                            $uibModalInstance.dismiss(false);
                        };
                    },
                    controllerAs: "commercialInvoiceRecalulateDateCtrl",
                    size: "md",
                    resolve: {
                    }
                }).result;
            }

            updateCommercialInvoiceDate_Click() {

                var temp: interfaces.logistics.ICommercialInvoiceRecalculateExchangeRate;
                temp = <interfaces.logistics.ICommercialInvoiceRecalculateExchangeRate>{ CommercialInvoice: this.CommercialInvoice, NewDate: this.CommercialInvoice.Date, Comments: "", RecalculateExchangeRate: false };

                this.displayUpdateInvoiceDateConfirmationBox(temp).then((result) => {
                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'con.update.com'
                        },
                            () => {
                                return this.updateCommercialInvoiceDate(result).then((data) => {
                                    
                                    this.$anchorScroll("topAnchor");
                                    this.loadCommercialInvoice();
                                }, (data) => {
                                    this.$anchorScroll("topAnchor");
                                });
                            })
                    }
                });
            }

            //Weight and Cubes Click
            weightAndCubes_Click() {
                //Go to the weight page.
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update'
                }, () => {
                    return this.menuService.getGTSConnectMenuItem(565).get((result: interfaces.applicationcore.IMenu) => {
                        if (result.url) {
                            result.url += "?Id=" + this.CommercialInvoice.Id;
                            var url = this.$state.href("auth.IFrame", { url: result.url });
                            window.open(url, '_blank');
                        }
                    }).$promise;
                });
            }
            
            calcPaymentDueDate_Click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.com'
                },
                    () => {
                        return this.commercialInvoiceService.calculatePaymentDueDate(this.isInbound, this.CommercialInvoice.Id).save((data: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(data);

                            if (!data.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.loadCommercialInvoice();
                                });
                            }

                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    });
            }
            
            apportionmentChanged() {
                if (this.apiSundryList) {
                    this.apiSundryList.rowEdit.setRowsDirty(this.CommercialInvoice.CommercialInvoiceSundryItemList);
                }
            }

            loadALCApportionment() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CustomsApportionment
                }, () => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadWarehouses() {
                if (this.CommercialInvoice && this.CommercialInvoice.LoadingCountryId) {
                    return this.warehouseService.getDropdownList().query({
                        countryId: this.CommercialInvoice.LoadingCountryId,
                        ownerEntityId: this.CommercialInvoice.OwnerEntity.Id
                    }, () => {
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadUoMList() {
                if (this.CommercialInvoice) {
                    return this.unitOfMeasureService.getUoMDropdown().query({ ownerEntityId: this.CommercialInvoice.OwnerEntity.Id }, () => { }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            loadDIs() {
                if (this.CommercialInvoice && this.CommercialInvoice.ConsignmentId) {
                    return this.commercialInvoiceService.getDILookupSearchByConsignmentId(this.CommercialInvoice.ConsignmentId).query(() => { }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            private sortState: { columnName: string; direction: 'asc' | 'desc' }

            setSortState(column: string, direction: 'asc' | 'desc') {

                this.sortState = { columnName: column, direction: direction };  

              }

              getSortState() {
                return this.sortState;
              }

            gvwSku: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerSkuGridApi(gridApi); },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    field: "CommercialInvoiceSKUId",
                    enableFiltering: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button"  ng-click="grid.appScope.commercialInvoiceUpdateCtrl.editSKULineClick(MODEL_COL_FIELD)"  class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-disabled="grid.appScope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType >= 1" ng-click="grid.appScope.commercialInvoiceUpdateCtrl.addInlineSku()">
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>`
                }, {
                    name: "SEQNUM",
                    displayName: "#",
                    field: "SequenceNumber",
                    enableFiltering: false,
                    type: "number",
                    cellClass: 'text-right',
                    width: 30,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false
                }, {
                    name: "PRODCODE",
                    displayName: "Product",
                    field: "ProductCode",
                    width: 100,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "SKUCODE",
                    displayName: "* SKU",
                    field: "SKU",
                    enableFiltering: true,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                    width: 200,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>',
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ng-disabled="row.entity.IsLinked" ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.commercialInvoiceUpdateCtrl.loadSKUs(searchText)" required="true" pagination-search="true" refresh-delay="1000"
                                                ng-change="grid.appScope.commercialInvoiceUpdateCtrl.skuChanged(model, row.entity)"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiSkuList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "SKUDESCRIPTION",
                    displayName: "SKU Description",
                    field: "SKU.Description",
                    width: 300,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 2 : true },
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-skudesc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                     filter: {
                                condition: (searchTerm, cellValue) => {
                                    return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                }
                            },                            
                }, {
                    name: "ORDQTY",
                    displayName: "* Order Qty",
                    field: "OrderedQty",
                    type: "number",
                    cellFilter: 'number: 5',
                    width: 90,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                    cellClass: 'text-right',
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ng-disabled="grid.appScope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType >= 1 || (!grid.appScope.commercialInvoiceUpdateCtrl.consignmentTypeDefaults.AllowAmendmentCIPriceWhenLinkedtoSO && row.entity.IsLinked)" name="orderedQty" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.00000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "ORDUOM",
                    displayName: "Order UoM",
                    field: "OrderUnitOfMeasureCode",
                    width: 90,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "PRCQTY",
                    displayName: "Price Qty",
                    cellFilter: 'number: 5',
                    field: "PriceQty",
                    type: "number",
                    width: 90,
                    cellClass: 'text-right',
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ng-disabled="grid.appScope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType >= 1 || (!grid.appScope.commercialInvoiceUpdateCtrl.consignmentTypeDefaults.AllowAmendmentCIPriceWhenLinkedtoSO && row.entity.IsLinked)" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "PRCUOM",
                    displayName: "Price UoM",
                    field: "PricingUnitOfMeasureCode",
                    width: 90,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }, {
                        name: "COSTPRICE",
                        displayName: "Cost Price",
                        field: "CostPrice",
                        type: "number",
                        cellFilter: "number: 5",
                        cellClass: 'text-right',
                        width: 125,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ng-disabled="grid.appScope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType >= 1" name="costPrice" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    },
                    {
                    name: "SUPPRICE",
                    displayName: "* Selling Price",
                    field: "SellingPrice",
                    type: "number",
                    cellFilter: "number: 5",
                    cellClass: 'text-right',
                        width: 125,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ng-disabled="grid.appScope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType >= 1 || (!grid.appScope.commercialInvoiceUpdateCtrl.consignmentTypeDefaults.AllowAmendmentCIPriceWhenLinkedtoSO && row.entity.IsLinked)" name="supplierPrice" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "SUPVALUE",
                    displayName: "Selling Value",
                    field: "SellingValue",
                    type: "number",
                    cellFilter: "number: 2",
                    cellClass: 'text-right',
                        width: 125,
                        enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="sellingValue" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                    }, {
                        name: "BASESUPPRICE",
                        displayName: "* Base Selling Price",
                        field: "BaseCurrencyPrice",
                        type: "number",
                        cellFilter: "number: 5",
                        cellClass: 'text-right',
                        width: 125,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ng-disabled="grid.appScope.commercialInvoiceUpdateCtrl.CommercialInvoice.LockdownType >= 1 || (!grid.appScope.commercialInvoiceUpdateCtrl.consignmentTypeDefaults.AllowAmendmentCIPriceWhenLinkedtoSO && row.entity.IsLinked)" name="supplierPrice" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "BASESUPVALUE",
                        displayName: "Base Selling Value",
                        field: "BaseCurrencyValue",
                        type: "number",
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        width: 125,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="sellingValue" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    },
                {
                    name: "VATVALUE",
                    displayName: "VAT Value",
                    field: "VATValue",
                    type: "number",
                    cellFilter: "number: 2",
                    cellClass: 'text-right',
                    width: 125,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="VATValue" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                },
                {
                    name: "CUSTOMSVALUE",
                    displayName: "Customs Value",
                    field: "CustomsValue",
                    type: "number",
                    cellFilter: "number: 2",
                    cellClass: 'text-right',
                    width: 125,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 1 : true },

                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="VATValue" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                },                
                {
                    name: "CURRENCY",
                    displayName: "Currency",
                    field: "Currency",
                    width: 100,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }
                    ,
                    {
                        name: "COO",
                        displayName: "CoO",
                        field: "CountryOfOriginCode",
                        width: 100,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }
                    , {
                        name: "ISGIVEAWAY",
                        displayName: "Is Give Away?",
                        field: "IsGiveAway",
                        width: 175,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" disabled>',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }, {
                        name: "REFERENCE",
                        displayName: "Reference",
                        field: "ReferenceNumber",
                        width: 300,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.CommercialInvoice ? this.CommercialInvoice.LockdownType < 2 : true },
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-skudesc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }]
            };

            skuChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.logistics.ICommercialInvoiceLineSKUDisplay) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'so.update'
                    },
                        () => {
                            return this.skuService.getSKU().query({ skuId: model.Id }, (defaults: interfaces.master.ISKU) => {

                              
                                entity.SKU = model;
                                entity.ProductId = defaults.ProductId;
                                entity.ProductCode = defaults.Product.Code;
                                entity.OrderUnitOfMeasureCode = defaults.Product.UnitOfMeasureCode;
                                entity.OrderUoMId = defaults.Product.OrderUnitOfMeasureId;
                                entity.PricingUnitOfMeasureCode = defaults.Product.UnitOfPricingCode;
                                entity.PricingUoMId = defaults.Product.PricingUnitOfMeasureId;
                                entity.OrderedQty = 0;
                                entity.PriceQty = 0;
                                entity.CostPrice = 0;
                                entity.SellingPrice = 0;
                                entity.SellingValue = 0;
                                entity.BaseCurrencyPrice = 0;
                                entity.BaseCurrencyValue = 0;

                                this.$timeout(() => {
                                    this.apiSkuList.rowEdit.setRowsDirty([entity]);
                                });
                            });
                        });
                }
            };

            registerSkuGridApi(gridApi: uiGrid.IGridApi) {
                this.apiSkuList = gridApi;



                this.$timeout(() => {
                    this.showHideSKUColumn(this.apiSkuList);
                });
                
                
                this.apiSkuList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiSkuList.selection.selectRow(gridSelection.row.entity);
                    this.selectedSKUCommercialInvoiceLineId = gridSelection.row.entity.CommercialInvoiceLineId;
                });

                this.apiSkuList.core.on.sortChanged(this.$scope, (grid: any, sortColumns: any[]) => {
                    if (sortColumns.length > 0) {
                      const column = sortColumns[0].field;
                      const direction = sortColumns[0].sort.direction;
                      this.setSortState(column, direction);
                    }
                  });                
            }

            loadSKUs(searchText: string) {
                if (this.CommercialInvoice) {
                    return this.skuService.getDropdownList(this.CommercialInvoice.OwnerEntity.Id, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            addInlineSku() {
                var Sku = {
                    CommercialInvoiceSKUId: 0,
                    CommercialInvoiceLineId : 0,
                    CommercialInvoiceId: this.CommercialInvoice.Id,
                    CurrencyId: this.CommercialInvoice.Currency.Id,
                    Currency: this.CommercialInvoice.Currency.Code
                };

                (<Array<any>>(this.gvwSku.data)).splice(0, 0, Sku);
            }


            editSKULineClick(cisId: number) {
                
                if (cisId) {
                }
                else {
                    cisId = 0;
                }

            

                if (this.CommercialInvoice.ConsignmentId) {
                  
                    if (this.$stateParams.manId) {
                         if (this.isInbound) {
                             this.$state.go("auth.Manifests.Update.ConsignmentImport.CommercialInvoice.Sku", { cisId: cisId, comId: this.CommercialInvoice.Id, conId: this.CommercialInvoice.ConsignmentId });
                         } else {                        
                             this.$state.go("auth.Manifests.Update.ConsignmentExport.CommercialInvoice.Sku", { cisId: cisId, comId: this.CommercialInvoice.Id, conId: this.CommercialInvoice.ConsignmentId });
                            }
                        }
                     else {

                        if (this.isInbound) {
                            this.$state.go("auth.ConsignmentImports.Update.CommercialInvoice.Sku", { cisId: cisId, comId: this.CommercialInvoice.Id, conId: this.CommercialInvoice.ConsignmentId });
                        } else {
                            this.$state.go("auth.ConsignmentExports.Update.CommercialInvoice.Sku", { cisId: cisId, comId: this.CommercialInvoice.Id, conId: this.CommercialInvoice.ConsignmentId });
                        }
                    }
                }
            }

            detailRemoveClick() {
                var selectedRows = <Array<interfaces.logistics.ICommercialInvoiceLineSKUDisplay>>this.apiSkuList.selection.getSelectedRows();

                if (selectedRows && selectedRows.length <= 0) {
                    return;
                }

                if (!selectedRows[0].CommercialInvoiceLineId || selectedRows[0].CommercialInvoiceLineId === 0) {
                    this.apiSkuList.rowEdit.setRowsClean(selectedRows);
                    (<Array<any>>(this.gvwSku.data)).splice((<Array<any>>(this.gvwSku.data)).lastIndexOf(selectedRows[0]), 1);
                    return;
                }

                this.generalService.displayConfirmationBox("Confirm?", "Are you sure you want to remove the SKU Line?").then((result: boolean) => {
                    if (result) {
                        this.removeLine(selectedRows[0].CommercialInvoiceLineId);
                    }
                });
            }

            removeLine(commercialInvoiceLineId: number) {
                this.commercialInvoiceSkuService.delete().delete({ commercialInvoiceLineId: commercialInvoiceLineId }, (result) => {
                    this.generalService.displayMessageHandler(result);

                    this.loadCommercialInvoice();

                }, (failureMessage) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                });
            }

            changeTermsOfDeliveryDescription() {
                return this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                    <div class="modal-header">
                        <h3 class="modal-title" id="modal-title">Edit Terms of Delivery Description</h3>
                    </div>
                    <div class="modal-body" id="modal-body">
                        <p>
                            Change Terms of Delivery Description below:
                        </p>
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input class="form-control" type="text" ng-model="termsOfDeliveryCtrl.termsOfDeliveryDescription"
                                    aria-label="Unloading Location Description">
                            </p>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" type="button" ng-click="termsOfDeliveryCtrl.ok()">Ok</button>
                        <button class="btn btn-info" type="button" ng-click="termsOfDeliveryCtrl.cancel()">Close</button>
                    </div>`,
                    controller: class termsOfDeliveryCtrl {
                        termsOfDeliveryDescription: string;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, termsOfDeliveryDescription: string) {
                            this.termsOfDeliveryDescription = termsOfDeliveryDescription;
                        }

                        ok() {
                            this.$uibModalInstance.close(this.termsOfDeliveryDescription);
                        }

                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }
                    },
                    controllerAs: "termsOfDeliveryCtrl",
                    resolve: {
                        termsOfDeliveryDescription: () => { return this.CommercialInvoice.TermsOfDelivery.Description; }
                    }
                }).result.then((termsOfDeliveryDescription) => {
                    this.CommercialInvoice.TermsOfDelivery.Description = termsOfDeliveryDescription;
                    this.CommercialInvoice.TermsOfDelivery.Display = this.CommercialInvoice.TermsOfDelivery.Code + " - " + this.CommercialInvoice.TermsOfDelivery.Description;
                });
            }

            selectComments_Click() {
                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Consignment Comments</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="vertical-align">
                                        <div class="col-md-12">
                                            <div id="gridTest" ui-grid="gvwComments" ui-grid-resize-columns ui-grid-selection class="gridSmallScreen"
                                                    ng-if="gvwComments">
                                                    <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="IsLoading">
                                                        <div class="loadingGrid" ng-show="IsLoading">
                                                            <i class="fa fa-spinner fa-spin"></i>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                                    <button class="btn btn-info" type="button" ng-click="termsOfDeliveryCtrl.cancel()">Close</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, consignmentService: interfaces.consignment.IConsignmentService) => {

                        $scope.ok = () => {
                            if (!angular.isDefined(this.apiCommentsList)) {
                                return;
                            }

                            var selectedRows: Array<interfaces.consignment.IConsignmentComments> = this.apiCommentsList.selection.getSelectedRows();

                            if (!selectedRows || selectedRows.length <= 0) {
                                this.generalService.displayMessage("At least one comment should be selected.", Enum.EnumMessageType.Warning);
                                return;
                            }

                            var resultString = "";
                            for (var i = 0; i < selectedRows.length; i++)
                                resultString += selectedRows[i].Comments + "\r\n";


                            $uibModalInstance.close(resultString);
                        };

                        $scope.cancel = () => {
                            $uibModalInstance.dismiss();
                        };

                        $scope.gvwComments = {
                            data: [],
                            enableFiltering: true,
                            useExternalFiltering: false,
                            enableCellEdit: false,
                            useExternalSorting: false,
                            multiSelect: true,
                            enableColumnResizing: true,
                            enableRowSelection: true,
                            enableFullRowSelection: true,
                            useExternalPagination: false,
                            enableRowHeaderSelection: false,
                            enablePinning: false,
                            rowEditWaitInterval: -1,
                            enableHorizontalScrollbar: 2,
                            enableVerticalScrollbar: 1,
                            onRegisterApi: (gridApi) => {
                                this.apiCommentsList = gridApi;
                                $scope.IsLoading = true;

                                consignmentService.GetConsignmentComments(this.CommercialInvoice.ConsignmentId).query((result: interfaces.consignment.IConsignmentComments) => {
                                    $scope.gvwComments.data = result;
                                    $scope.IsLoading = false;
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    $scope.IsLoading = false;
                                });
                            },
                            columnDefs: [
                                {
                                    name: "RowNumber",
                                    displayName: "#",
                                    field: "RowNumber",
                                    type: "number",
                                    enableColumnMenu: true,
                                    width: 100,
                                },
                                {
                                    name: "Comments",
                                    displayName: "Comments",
                                    field: "Comments",
                                    type: "string",
                                    enableColumnMenu: true,
                                    width: 900,
                                }, ]
                        };
                    },
                    size: "xlg",
                    resolve: {
                    }
                }).result.then((resultString) => {
                    this.CommercialInvoice.Comments = resultString;
                });
            }

            
            DisplayPreviousProcedureLineSearch_Click(){
                
                this.DisplayPreviousProcedureLineSearch().then((previousDeclarationLinesCI:Array<interfaces.customs.IPreviousDeclarationLineCI> ) => {
                    if (previousDeclarationLinesCI) {
                        return this.bsLoadingOverlayService.wrap({
                                referenceId: 'con.update.com'
                            }, () => {
                        
                                return this.goodsDeclarationLineService.copyLinesFromPreviousProcedureLineCI().save(previousDeclarationLinesCI, (result: interfaces.applicationcore.IMessageHandler) => {
                                    this.$anchorScroll("topAnchor");
                                    this.generalService.displayMessageHandler(result);
                
                                    if (!result.HasErrorMessage) {
                                        this.loadCommercialInvoice();
                                    }
                                }, (errorResponse) => {
                                    this.$anchorScroll("topAnchor");
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                            });
                        }
                    });
            }

            DisplayPreviousProcedureLineSearch(){
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="manualDeclaration">
                                <div class="gts-padding">
                                    <fieldset id="varianceAuth">
                                    <legend>
                                        Previous Procedure Assessment Line Number Search
                                    </legend>    
                                            <div class="row vertical-align">
                                                <div class="col-md-2">
                                                
                                                <input type="checkbox" name="manualDeclaration"
                                                    ng-model="declarationLineSearchCtrl.showAll" 
                                                    ng-change="declarationLineSearchCtrl.GetPreviousProcedureGoodsDeclarationLines()"
                                                    />
                                                                                                   
                                                    <opt></opt>
                                                    <label>Show All</label>
                                                </div>
                                                   <!-- <div class="col-md-1">
                                                    <p class="input-group-sm has-feedback"
                                                        ng-class="{'has-error': filterForm.manualDeclaration.invalid}">
                                                        <input type="checkbox" name="manualDeclaration"
                                                            ng-model="declarationLineSearchCtrl.showAll" 
                                                            ng-change="declarationLineSearchCtrl.GetPreviousProcedureGoodsDeclarationLines()"
                                                            />
                                                    </p>                
                                                </div>-->
                                                <div class="col-md-4">

                                                <i class="fa fa-question-circle"
                                                        uib-tooltip='If the consignment is from a Bonded Warehouse then the Declarations To Warehouse Customs Code will be filtered by the Customs code of the Dispatch Warehouse, and only where there is available quantity in the Bond Warehouse.'
                                                        tooltip-placement="right">
                                                    </i>  
                                                    
                                                </div>

                                                
                                                <div class="col-md-1" style="background-color:#e66a00;height:8px;">

                                                </div>
                                                <div class="col-md-5">
                                                    Indicates previously selected Goods Declaration Line
                                                </div>
                                            </div>

                                            <div id="gvwPreviousProcedureLines" ui-grid="declarationLineSearchCtrl.gvwPreviousProcedureLines" ui-grid-pagination ui-grid-resize-columns ui-grid-selection class="gridFullScreen">
                                                <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="!declarationLineSearchCtrl.gvwPreviousProcedureLines.data || declarationLineSearchCtrl.IsLoading">
                                                    <div class="msg" ng-show="(!declarationLineSearchCtrl.gvwPreviousProcedureLines.data ) && !declarationLineSearchCtrl.IsLoading">
                                                        <span>No Rows</span>
                                                    </div>
                                                    <div class="loadingGrid" ng-show="declarationLineSearchCtrl.IsLoading">
                                                        <i class="fa fa-spinner fa-spin"></i>
                                                    </div>
                                                </div>
                                            </div>
                                                                                                                                                                                                     

                                            <div class="gts-padding pull-right">                                               
                                            <button type="button" class="btn btn-primary" ng-click="declarationLineSearchCtrl.ok()" ng-disabled = "declarationLineSearchCtrl.disableOkButton">Add</button>
                                            <button type="button" class="btn btn-info" ng-click="declarationLineSearchCtrl.cancel()">Cancel</button>
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class declarationLineSearchCtrl {

                        paginationOptions = {
                            pageNumber: 1,
                            pageSize: 25
                        };

                        showAll:boolean = false;
                        gridApi: uiGrid.IGridApi;

                        sortName: string;
                        sortDirection: string;
                        IsLoading : boolean = false;
                        selectedLine: uiGrid.IGridRow;
                        disableOkButton: boolean = true;
                        selectedPreviousProcedureGoodsDeclarationLineCI:Array<interfaces.customs.PreviousProcedureGoodsDeclarationLineCI> = [];
                        
                        constructor(private $scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,  private $q: ng.IQService,
                            private goodsDeclarationLineService: interfaces.customs.IGoodsDeclarationLineService,
                            private generalService: interfaces.applicationcore.IGeneralService,
                            private $timeout: ng.ITimeoutService,
                            private consignmentId: number,
                            private commercialInvoiceId:number
                            ) {
                                this.GetPreviousProcedureGoodsDeclarationLines();
                        }

                        GetPreviousProcedureGoodsDeclarationLines(){
                            var searchObject: interfaces.applicationcore.ISearchObject = {
                                filters: [],
                                sorts: []
                            }

                            if (this.gridApi) {
                                var grid = this.gridApi.grid;
                                angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                    if (n.filters[0].term) {
                                        searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                    }
    
                                    if (n.sort.direction) {
                                        searchObject.sorts.push({
                                            Name: n.name,
                                            SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                            SortOrder: n.sort.priority
                                        });
                                    }
                                });
                            }
                            
                            var params = {
                                connectSearch: searchObject,
                                consignmentId : this.consignmentId,
                                commercialInvoiceId : this.commercialInvoiceId,
                                showAll : this.showAll,
                                numberRecords: this.paginationOptions.pageSize,
                                pageNumber: this.paginationOptions.pageNumber,
                            }

                            return this.goodsDeclarationLineService.getPreviousProcedureLineCIViewModel().query(
                                params, (result: Array<interfaces.customs.PreviousProcedureGoodsDeclarationLineCI>) => {
                                   
                                    this.gvwPreviousProcedureLines.data = result;

                                    if (result[0]) {
                                        this.gvwPreviousProcedureLines.totalItems = result[0].NumRecords;
                                    } else {
                                        this.gvwPreviousProcedureLines.totalItems = 0;
                                    }

                                    this.gridApi.selection.clearSelectedRows();
                                    for (let item of this.gvwPreviousProcedureLines.data) {
                                        var foundItem = _.find(this.selectedPreviousProcedureGoodsDeclarationLineCI, item as interfaces.customs.PreviousProcedureGoodsDeclarationLineCI);
                                        if (foundItem) {

                                            this.$timeout(() => {
                                                this.gridApi.selection.unSelectRow(item);
                                                this.gridApi.selection.selectRow(item);
                                            });
                                        }
                                    }
                               
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;

                        }



                        sortChange(gridApi) {
                            this.GetPreviousProcedureGoodsDeclarationLines();
                        }
                        
                        filterChange(gridApi: uiGrid.IGridApi) {
                            this.GetPreviousProcedureGoodsDeclarationLines();
                        }

                        
                        ListPageChange(newPage, pageSize) {

                            this.paginationOptions.pageNumber = newPage;
                            this.paginationOptions.pageSize = pageSize;

                            this.GetPreviousProcedureGoodsDeclarationLines();
                        }

                        public registerGridApi(gridApi: uiGrid.IGridApi) {
                            this.gridApi = gridApi;
            
                            this.gridApi.core.on.filterChanged(this.$scope, () => { this.filterChange(gridApi) });
                            this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                                this.sortChange(gridApi);
                                if (sortColumns[0]) {
                                    this.sortName = sortColumns[0].name;
                                    this.sortDirection = sortColumns[0].sort.direction;
                                }
                                else {
                                    this.sortName = "";
                                    this.sortDirection = "";
                                }
                            });
            
            
                            this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });


                            //ROW SELECTION

                            this.gridApi.selection.on.rowSelectionChangedBatch(this.$scope,(rows)=>{
                                
                                for (let selected of rows){
                                    var foundItem = _.find(this.selectedPreviousProcedureGoodsDeclarationLineCI, selected.entity);

                                    if(!foundItem) {
                                        if (selected.isSelected){
                                            this.selectedPreviousProcedureGoodsDeclarationLineCI = _.concat(this.selectedPreviousProcedureGoodsDeclarationLineCI, selected.entity);  
                                        } 
                                    }
                                    else {
                                        _.remove(this.selectedPreviousProcedureGoodsDeclarationLineCI, selected.entity);
                                    }
                                }

                                this.disableOkButton = false;

                                if (this.gridApi.selection.getSelectedRows().length === 0) {
                                    this.disableOkButton = true;                                    
                                }
                                else{
                                    this.disableOkButton = false;
                                }
                            });


                            this.gridApi.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                                
                                this.selectedLine = selected;  
                                var foundItem = _.find(this.selectedPreviousProcedureGoodsDeclarationLineCI, selected.entity);

                                if(!foundItem) {
                                    if (selected.isSelected){
                                        this.selectedPreviousProcedureGoodsDeclarationLineCI = _.concat(this.selectedPreviousProcedureGoodsDeclarationLineCI, selected.entity);  
                                    } 
                                }
                                else {
                                    _.remove(this.selectedPreviousProcedureGoodsDeclarationLineCI, selected.entity);
                                }

                                this.disableOkButton = false;

                                if (this.gridApi.selection.getSelectedRows().length === 0) {
                                    this.disableOkButton = true;                                    
                                }
                                else{
                                    this.disableOkButton = false;
                                }
                            });


                        }

                        public gvwPreviousProcedureLines: uiGrid.IGridOptions | any = {
                            data: [],
                            enableSorting: true,
                            enableFiltering: true,
                            useExternalFiltering: true,
                            enableCellEdit: false,
                            useExternalSorting: true,
                            multiSelect: true,
                            enableColumnResizing: true,
                            enableRowSelection: true,
                            enableFullRowSelection: true,
                            enableRowHeaderSelection: true,                            
                            showGridFooter: true,
                            enableHorizontalScrollbar: 2,
                            enablePinning: false,
                            rowEditWaitInterval: -1,
                            paginationPageSizes: [25, 50, 75, 100],
                            paginationPageSize: 25,
                            enableVerticalScrollbar: 1,
                            useExternalPagination: true,
                            enableGridMenu: false,
                            exporterMenuCsv: false,
                            exporterMenuPdf: false,
                            exporterMenuExcel: false,
                            gridMenuShowHideColumns: false,
                            exporterFieldCallback: (grid, row, col, value) => {
                                return this.generalService.handleValue(value);
                            },
                            exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                            onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                            rowTemplate: `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader, 'SLA-amber': row.entity.PreviouslyLinkedCommercialInvoiceLineId>0}" ui-grid-cell></div>`,
                            columnDefs: [ 
                                {
                                    name: "Status",
                                    field: "Status",
                                    displayName: "",
                                    enableFiltering: false,
                                    cellTemplate: `<div style="text-align: center; padding-top: 6px;" >
                                                        <div ng-if="row.entity.Status === 0">
                                                            <span class="fa fa-check-circle" style="color: #bcd856;"
                                                           
                                                            ></span>
                                                        </div>
                                                        <div ng-if="row.entity.Status === 1">
                                                            <span class="fa fa-exclamation-triangle" style="color: #f57f20; opacity: 0.7;"
                                                            uib-tooltip='{{row.entity.Days}} Days in the Bond Warehouse' tooltip-placement="right"
                                                            ></span>
                                                        </div>
                                                        <div ng-if="row.entity.Status === 2">
                                                            <span class="fa fa-times" style="color: #cb2330;"
                                                            uib-tooltip='{{row.entity.Days}} Days in the Bond Warehouse' tooltip-placement="right"
                                                            ></span>
                                                        </div>
                                                    </div>`,
                                    enableSorting: false,
                                    enableColumnMenu: false,
                                    width: 35
                                },                               
                                {
                                    name: "EntityCode",
                                    displayName: "Entity",
                                    field: "EntityCode",
                                    width: 60,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                }, 
                                {
                                    name: "Consignment",
                                    displayName: "Consignment",
                                    field: "Consignment",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },
                                {
                                    name: "CustomsOffice",
                                    displayName: "Customs Office",
                                    field: "CustomsOffice",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },      
                                {
                                    name: "MRN",
                                    displayName: "MRN",
                                    field: "MRN",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                }, 
                                {
                                    name: "AssessmentDate",
                                    displayName: "Assessment Date",
                                    field: "AssessmentDate",
                                    cellFilter: 'momentDateFilter:"YYYY/MM/DD"',
                                    width: 140,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="estimateDateOfLoading"></gts-date>
                                            </div>`,
                                },       
                                {
                                    name: "ToWarehouse",
                                    displayName: "To Warehouse",
                                    field: "ToWarehouse",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },       
                                {
                                    name: "ToWarehouseCustomsCode",
                                    displayName: "Customs Code",
                                    field: "ToWarehouseCustomsCode",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },       
                                {
                                    name: "CPC",
                                    displayName: "CPC",
                                    field: "CPC",
                                    width: 150,
                                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                                </div>
                                            </div>`,
                                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                },                       
                                {
                                name: "GoodsDecLineNo",
                                displayName: "#",
                                field: "GoodsDecLineNo",
                                width: 40,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },       
                            {
                                name: "CommodityCode",
                                displayName: "Commodity Code",
                                field: "CommodityCode",
                                width: 150,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },       
                            {
                                name: "Origin",
                                displayName: "Origin",
                                field: "Origin",
                                width: 150,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },     
                            {
                                name: "CustomsValue",
                                displayName: "Customs Value",
                                field: "CustomsValue",
                                width: 150,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },
                            {
                                name: "QuantityRemaining",
                                displayName: "Quantity Remaining",
                                field: "QuantityRemaining",
                                width: 180,
                                cellClass: 'text-right',
                                cellFilter: 'number: 2',
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                            },                               
                            {
                                name: "CountableQauntityCode",
                                displayName: "Code",
                                field: "CountableQauntityCode",
                                width: 40,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },                                                             
                            {
                                name: "ProcedureMeasure",
                                displayName: "Procedure Measure",
                                field: "ProcedureMeasure",
                                width: 150,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },
                            {
                                name: "InvoiceLineNo",
                                displayName: "#",
                                field: "InvoiceLineNo",
                                width: 40,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },  
                            {
                                name: "SKUCode",
                                displayName: "SKU Code",
                                field: "SKUCode",
                                width: 100,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },
                            {
                                name: "LineDescription",
                                displayName: "Description",
                                field: "LineDescription",
                                width: 150,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },{
                                name: "OrderQty",
                                displayName: "Order Qty",
                                field: "OrderQty",
                                width: 180,
                                cellClass: 'text-right',
                                cellFilter: 'number: 2',
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                            },
                            {
                                name: "OrderUoM",
                                displayName: "UoM",
                                field: "OrderUoM",
                                width: 40,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },
                            ,{
                                name: "LineValue",
                                displayName: "Line Value",
                                field: "LineValue",
                                width: 180,
                                cellClass: 'text-right',
                                cellFilter: 'number: 2',
                                filterHeaderTemplate: `
                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                        <div class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                        </div>
                                    </div>`
                            },   
                            {
                                name: "Currency",
                                displayName: "Currency",
                                field: "Currency",
                                width: 60,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`,
                                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                            },                                             
                            ]                
                        }

                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }

                        ok() {

                            const previousDeclarationLinesCI: Array<interfaces.customs.IPreviousDeclarationLineCI> = this.selectedPreviousProcedureGoodsDeclarationLineCI.map(
                                (item) => {
                                  // For each element in the selectedPreviousProcedureGoodsDeclarationLineCI array,
                                  // create a new object of type IPreviousDeclarationLineCI with the required properties.
                                  return {
                                    CommercialInvoiceId: this.commercialInvoiceId,
                                    PreviousProcedureGoodsDeclarationLineId: item.PreviousProcedureGoodsDeclarationLineId,
                                    PreviousProcedureCommercialInvoiceLineId: item.PreviousProcedureCommercialInvoiceLineId,
                                    PreviousProcedureCommercialInvoiceSkuId: item.PreviousProcedureCommercialInvoiceSkuId
                                  };
                                });

                                // Create a Set to keep track of the unique keys
                                const uniqueKeys: Set<string> = new Set();

                                // Filter out duplicates and create a new array
                                const filteredPreviousDeclarationLinesCI = previousDeclarationLinesCI.filter((item) => {
                                const key = `${item.CommercialInvoiceId}_${item.PreviousProcedureGoodsDeclarationLineId}_${item.PreviousProcedureCommercialInvoiceLineId}_${item.PreviousProcedureCommercialInvoiceSkuId}`;
                                if (!uniqueKeys.has(key)) {
                                    uniqueKeys.add(key); // Add the key to the Set if it's not already present
                                    return true; // Include the item in the filtered array
                                }
                                return false; // Exclude the item from the filtered array as it's a duplicate
                                });                                

                            this.$uibModalInstance.close(filteredPreviousDeclarationLinesCI);
                        }

                                
                    },
                    controllerAs: "declarationLineSearchCtrl",
                    size: "lg",
                    resolve: {
                         consignmentId: () => { return this.$state.params['conId'] },
                         commercialInvoiceId : () => { return this.$state.params['comId'] }
                       
                    }
                }).result;
            }

            ApplyCPCWizard() {
                //Show CPC Wizard
                this.$uibModal.open({
                    animation: true,
                    template: `
                    <form name="ChangeCPCWizard">
                        <div class="modal-content">
                            <div class="gts-padding">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Select a Customs Procedure</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div ng-if="Messages.length > 0">
                                        <gts-message-display messages="Messages"></gts-message-display>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-5">
                                            <req></req>
                                            <label>Procedure Category</label>
                                        </div>
                                        <div class="col-md-5">
                                            <gts-dropdown name="procedureCategory" ng-model="ProcedureCategory" form="ChangeCPCWizard" load-data="loadProcedureCategory()" ng-change="procedureCategoryChanged(model)" required="true"></gts-dropdown>
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-5">
                                            <req></req>
                                            <label>Customs Procedure</label>
                                        </div>
                                        <div class="col-md-5">
                                            <gts-dropdown name="customsProcedure" ng-model="CustomsProcedure" form="ChangeCPCWizard" load-data="loadCustomsProcedure()" required="true" ng-disabled="!ProcedureCategory" list="customsProcedures" ng-change="customsProcedureChanged(model)"  pagination-search="true" refresh-delay="500"></gts-dropdown>
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-5">
                                            <req></req>
                                            <label>Previous Customs Procedure</label>
                                        </div>
                                        <div class="col-md-5">
                                            <gts-dropdown name="previousCustomsProcedure" ng-model="PreviousCustomsProcedure" form="ChangeCPCWizard" load-data="loadPreviousCustomsProcedure()" required="false" ng-disabled="!CustomsProcedure" ng-change="previousCustomsProcedureChanged(model)" list="previousCustomsProcedures"></gts-dropdown>
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-5">
                                            <opt></opt>
                                            <label>Customs Procedure Matrix Measure</label>
                                        </div>
                                        <div class="col-md-5">
                                            <gts-dropdown name="customsProcedureMatrix" ng-model="CustomsProcedureMatrix" form="ChangeCPCWizard" load-data="loadCustomsProcedureMatrix()" required="false" ng-disabled="!CustomsProcedure" list="CustomsProcedureMatrixs"></gts-dropdown>
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-10">
                                            <textarea rows="3" name="description" readonly class="form-control">{{ProcedureCategory.Description + ' \n'}}{{CustomsProcedure.Description + ' \n'}}{{PreviousCustomsProcedure.Description + ' \n'}}</textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" ng-click="changeCPC()" ng-disabled="!(ProcedureCategory && CustomsProcedure)">OK</button>
                                    <button type="button" class="btn btn-info" ng-click="close()">Close</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                    ) => {
                        $scope.ProcedureCategory;
                        $scope.CustomsProcedure;
                        $scope.PreviousCustomsProcedure;
                        $scope.CustomsProcedureMatrix;
    
                        $scope.CustomsProcedureId = undefined;
                        $scope.PreviousCustomsProcedureId = undefined;
                        $scope.CustomsProcedureMatrixCode = undefined;
    
                        $scope.close = () => {
                            $uibModalInstance.dismiss();
                        }
    
                        $scope.procedureCategoryChanged = () => {
                            $scope.CustomsProcedure = undefined;
                            $scope.PreviousCustomsProcedure = undefined;
                            $scope.CustomsProcedureMatrix = undefined;
    
                            $scope.customsProcedures = undefined;
                            $scope.previousCustomsProcedures = undefined;
                            $scope.CustomsProcedureMatrixs = undefined;
                        };
    
                        $scope.customsProcedureChanged = () => {
                            $scope.PreviousCustomsProcedure = undefined;
                            $scope.previousCustomsProcedures = undefined;
                            $scope.CustomsProcedureMatrix = undefined;
                            $scope.CustomsProcedureMatrixs = undefined;
                        };
    
                        $scope.previousCustomsProcedureChanged = () => {
                            $scope.CustomsProcedureMatrix = undefined;
                            $scope.CustomsProcedureMatrixs = undefined;
                        };
    
                        //Load the Dropdowns
                        $scope.loadProcedureCategory = () => {
                            if (!this.CommercialInvoice) {
                                var deferred = this.$q.defer();
                                deferred.resolve([]);
                                return deferred.promise;
                            }
    
                            return this.customsProcedureMatrixService.getProcedureCategoryDropdownList((this.CommercialInvoice.IsInbound ? this.CommercialInvoice.UnloadingCountryId : this.CommercialInvoice.LoadingCountryId)).query({
                            }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, $scope);
                            }).$promise;
                        }
    
                        $scope.loadCustomsProcedure = (searchText) => {
                            if (!this.CommercialInvoice && !$scope.ProcedureCategory) {
                                var deferred = this.$q.defer();
                                deferred.resolve([]);
                                return deferred.promise;
                            }
    
                            return this.customsProcedureMatrixService.getCustomsProcedureDropdownList((this.CommercialInvoice.IsInbound ? this.CommercialInvoice.UnloadingCountryId : this.CommercialInvoice.LoadingCountryId), $scope.ProcedureCategory.Id).query({
                            }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, $scope);
                            }).$promise;
                        }
    
                        $scope.loadPreviousCustomsProcedure = () => {
                            if (!this.CommercialInvoice) {
                                var deferred = this.$q.defer();
                                deferred.resolve([]);
                                return deferred.promise;
                            }
    
                            return this.customsProcedureMatrixService.getPrevCustomsProcedureDropdownList((this.CommercialInvoice.IsInbound ? this.CommercialInvoice.UnloadingCountryId : this.CommercialInvoice.LoadingCountryId), $scope.ProcedureCategory.Id, $scope.CustomsProcedure.Id).query({
                            }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, $scope);
                            }).$promise;
                        }
    
                        $scope.loadCustomsProcedureMatrix = () => {
                            if (!this.CommercialInvoice) {
                                var deferred = this.$q.defer();
                                deferred.resolve([]);
                                return deferred.promise;
                            }
    
                            return this.customsProcedureMatrixMeasureService.getDropdownList((this.CommercialInvoice.IsInbound ? this.CommercialInvoice.UnloadingCountryId : this.CommercialInvoice.LoadingCountryId), $scope.ProcedureCategory.Id, $scope.CustomsProcedure.Id, $scope.PreviousCustomsProcedure.Id).query({
                            }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, $scope);
                            }).$promise;
                        }
    
                        //Submit
                        $scope.changeCPC = () => {
    
                            if ($scope.CustomsProcedure) {
                                $scope.CustomsProcedureId = $scope.CustomsProcedure.Id;
                            }
    
                            if ($scope.PreviousCustomsProcedure) {
                                $scope.PreviousCustomsProcedureId = $scope.PreviousCustomsProcedure.Id;
                            }
    
                            if ($scope.CustomsProcedureMatrix) {
                                $scope.CustomsProcedureMatrixCode = $scope.CustomsProcedureMatrix.Code;
                            }
    
    
                            return this.commercialInvoiceService.changeCPCCode(this.CommercialInvoice.Id, $scope.ProcedureCategory.Id, $scope.CustomsProcedureId, $scope.PreviousCustomsProcedureId, $scope.CustomsProcedureMatrixCode).save({
                            }, (result: interfaces.applicationcore.IMessageHandler) => {
    
                                if (!result.HasErrorMessage) {
                                }
    
                                this.generalService.displayMessageHandler(result);
                                this.$anchorScroll("topAnchor");
                                $uibModalInstance.dismiss();
    
                                this.$timeout(() => {
                                    this.loadCommercialInvoice();
                                });
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                this.$anchorScroll("topAnchor");
                                $uibModalInstance.dismiss();
                            }).$promise;
    
                        }
                    },
                    controllerAs: "viewCPCMatrixWizard",
                    size: "lg",
                    resolve: {
                    }
                });
            }

        }

        angular.module("app").controller("commercialInvoiceUpdateCtrl", controllers.logistics.CommercialInvoiceUpdateCtrl);
    }
}